<template>
  <PageCard
    pageName="User Access Requests"
    :iconClass="['fa', 'fa-users', 'fa-lg']"
  >
    <template slot="page_content">
      <div v-if="$apollo.loading">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-sm-3 text-left mb-3 mt-2">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-search"></i
                ></span>
              </div>
              <b-form-input
                type="text"
                v-model="filterText"
                @keydown.native.stop
                placeholder="Search"
              ></b-form-input>
            </b-input-group>
          </div>

          <div class="col-sm-12">
            <b-table
              :items="listingUsers"
              :fields="tableHeaders"
              :filter="filterText"
              :filter-included-fields="filterOn"
              @filtered="afterFilter"
              :show-empty="true"
              :current-page="currentPage"
              :per-page="perPage"
              empty-text="No records found."
              responsive
              striped
              hover
              small
            >
              <template v-slot:cell(actions)="data">
                <div class="text-right px-3">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm mx-2"
                    @click="approve(data.item)"
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm mx-2"
                    @click="selectGroup(data.item)"
                  >
                    Approve w/Group
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    @click="reject(data.item)"
                  >
                    Reject
                  </button>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col md="6">
                <b-pagination
                  :total-rows="filteredUsers ? filteredUsers.length : totalRows"
                  :per-page="perPage"
                  v-model="currentPage"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <AcceptWithGroup
        :roles="roles"
        :groups="groups"
        :selectedUser="selectedUser"
        @userApproved="fetchUsers"
      ></AcceptWithGroup>
    </template>
  </PageCard>
</template>

<script>
// global
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
// ui components
import PageCard from '@/modules/core/components/layouts/PageCard'
import gql from 'graphql-tag'
// api
import usersAPI from '@/api/finapps/super_admin/users'
import AcceptWithGroup from '@/modules/super_admin/components/AcceptWithGroup.vue'

export default {
  name: 'UserAccessRequestReport',
  components: {
    PageCard,
    AcceptWithGroup
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']
    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },

  created() {
    this.selectedUser = {}
    this.fetchInitData()
  },
  data() {
    return {
      users: [],
      currentPage: 1,
      perPage: 20,
      allUsers: [],
      filterOn: ['firstName', 'email', 'company', 'phone', 'role_name'],
      filterText: null,
      filteredUsers: null,
      selectedUser: {},
      roles: [],
      groups: []
    }
  },
  computed: {
    listingUsers() {
      return this.allUsers.filter(user => !user.isDeleted)
    },
    totalRows() {
      return this.listingUsers.length
    },
    tableHeaders() {
      return [
        { key: 'firstName', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'company', label: 'Company', sortable: true },
        { key: 'phone', label: 'Phone', sortable: true },
        // { key: 'role_name', label: 'Role', sortable: true },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false,
          class: 'text-center'
        }
      ]
    }
  },
  apollo: {
    allUsers: {
      query: gql`
        {
          allUsers(orderBy: "id") {
            id
            firstName
            email
            company
            phone
            confirmedAt
            isApproved
          }
        }
      `,
      client:
        window.location.host === process.env.VUE_APP_ETHOS_URL_NAME
          ? 'ethosClient'
          : 'apolloClient',
      update(data) {
        let usersData = data.allUsers.filter(user => user.isApproved === false)
        //let usersData = data.allUsers
        usersData.forEach(user => {
          user.isDeleted = false
          user.role_name = user.role ? user.role.name : null
        })
        return usersData
      }
    }
  },
  methods: {
    afterFilter: function(filteredItems) {
      this.filteredUsers = filteredItems
      this.currentPage = 1
    },
    fetchInitData() {
      this.$http
        .get('/api/group_users/new', {
          handleErrors: true,
          params: { super_admin: true }
        })
        .then(res => {
          this.roles = this.formatRoleData(res.data.roles)
          this.groups = this.formatGroupData(res.data.groups)
        })
    },
    formatRoleData(roles) {
      // roles.forEach(role => {
      //   role.alias = role.name === 'Admin' ? 'Super Admin' : role.name
      // })
      roles.sort((a, b) => b.name.length - a.name.length)
      return roles
    },
    formatGroupData(groups) {
      //filter only client groups
      groups = groups.filter(g => g.group_type === 'Client Group')
      groups.forEach(group => {
        group.alias = group.name + ' - ' + group.group_type
      })
      return groups
    },
    selectGroup(user) {
      this.selectedUser = user
      this.$bvModal.show(`approveWithGroup`)
    },
    approve(user) {
      return usersAPI.approveUser(user.id, true, null, null).then(() => {
        Vue.toasted.show('User approved successfully.', {
          icon: 'user-circle',
          type: 'success'
        })
        this.fetchUsers()
      })
    },
    reject(user) {
      return this.$http
        .delete(`/api/group_users/${user.id}`, {
          handleErrors: true
        })
        .then(() => {
          Vue.toasted.show('User rejected successfully.', {
            icon: 'user-circle',
            type: 'success'
          })
          this.fetchUsers()
        })
    },

    fetchUsers() {
      this.$apollo.queries.allUsers.refetch()
    }
  }
}
</script>

<style lang="scss" scoped></style>
