<template>
  <b-modal
    id="approveWithGroup"
    ref="approveWithGroup"
    @show="resetUser"
    size="md"
    title="Approve With Group"
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-form-group
      label="Email"
      label-for="email"
      :label-cols="3"
      :horizontal="true"
    >
      <b-form-input
        id="email"
        type="email"
        placeholder="Email"
        :value="selectedUser.email"
        autocomplete="off"
        :disabled="true"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="User Role"
      label-for="user_role"
      :label-cols="3"
      :horizontal="true"
    >
      <b-form-select
        id="user_role"
        v-model="roleId"
        text-field="name"
        value-field="id"
        :options="roles"
      >
      </b-form-select>
    </b-form-group>
    <div class="form-row form-group pb-2">
      <div class="offset-3 col-9 text-danger pr-1" v-if="superAdminSelected">
        <i class="fa fa-exclamation-triangle"></i>
        This role is for FIN internal use only.
      </div>
    </div>
    <b-form-group
      label="User Group"
      label-for="user_group"
      :label-cols="3"
      :horizontal="true"
    >
      <b-form-select
        id="user_group"
        v-model="groupId"
        text-field="alias"
        value-field="id"
        :options="groups"
      >
      </b-form-select>
    </b-form-group>

    <div slot="modal-footer">
      <div class="row text-right">
        <div class="col-sm-12">
          <b-button
            size=""
            variant="secondary"
            class="fw-btn mr-2"
            @click="hideModal"
            >Cancel</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            class="btn btn-primary fw-btn"
            @click="updateGroup"
            >Approve</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Vue from 'vue'
import usersAPI from '@/api/finapps/super_admin/users'
export default {
  name: 'ApproveWithGroup',
  props: {
    roles: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: false
    },
    selectedUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      roleId: null,
      groupId: null
    }
  },
  computed: {
    superAdminSelected() {
      return this.roleId === 1 //FIN Admin
    }
  },

  methods: {
    updateGroup() {
      return usersAPI
        .approveUser(this.selectedUser.id, true, this.roleId, this.groupId)
        .then(() => {
          Vue.toasted.show('User approved successfully.', {
            icon: 'user-circle',
            type: 'success'
          })
          this.$emit('userApproved')
          this.hideModal()
        })
    },
    hideModal() {
      this.resetUser()
      this.$refs.approveWithGroup.hide()
    },
    resetUser() {
      this.roleId = null
      this.groupId = null
    }
  }
}
</script>
